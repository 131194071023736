'use client'

import { FC, useEffect, useState } from 'react'
import { SectionArticlesLandingPageProps } from '@portfolio/models'
import { styled, useMediaQuery } from '@portfolio/styles'
import {
  Chip,
  Container,
  Media,
  SectionWrapper,
  TagsGroup,
} from '@portfolio/components'
import { useTranslation } from 'react-i18next'

const StyledSectionWrapper = styled(SectionWrapper, {
  marginBottom: '$mobileSectionMarginL',
  '@lg': {
    marginBottom: '$desktopSectionMarginL',
  },
})

const Title = styled('h1', {
  projectFont: 'heading01',
  textAlign: 'center',
  marginBottom: '$120',
})

const ArticlesGrid = styled('div', {
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$56',
  maxWidth: '100%',

  '@md': {
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '$64 $24',
  },
})

const Article = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$32',
  width: '100%',
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading07',
  gridRow: 3,
  gridColumn: 1,
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
  aspectRatio: '3 / 2',

  '@md': {
    aspectRatio: 'auto',
  },
})

const ReadArticle = styled('span', {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 4,
})

const ArticleTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gridColumn: 1,
  gridRow: 2,
  columnGap: '$12',
})

const ArticleTag = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

export const SectionArticlesLandingPage: FC<
  SectionArticlesLandingPageProps
> = ({ title, articles, priority }) => {
  const { t } = useTranslation('articles')
  const [selectedTag, setSelectedTag] = useState<string | null>(null)
  const [filteredArticles, setFilteredArticles] = useState(articles)
  const md = useMediaQuery('md')

  const tags = articles?.reduce<{ title: string; value: string }[]>(
    (acc, article) => {
      if (!article.tags) return acc

      article.tags.forEach((tag) => {
        if (!acc.some((existingTag) => existingTag.value === tag.value)) {
          acc.push(tag)
        }
      })

      return acc
    },
    [],
  )

  useEffect(() => {
    if (!selectedTag) {
      setFilteredArticles(articles)
      return
    }

    setFilteredArticles(
      articles?.filter((article) =>
        article.tags?.some((tag) => tag.value === selectedTag),
      ),
    )
  }, [selectedTag, articles])

  return (
    <StyledSectionWrapper type="FIRST_SECTION">
      {title && <Title>{title}</Title>}
      {tags && (
        <TagsGroup
          tags={tags}
          selectedTag={selectedTag}
          setSelectedTag={setSelectedTag}
        />
      )}
      <Container>
        <ArticlesGrid>
          {filteredArticles &&
            filteredArticles.map((article, index) => (
              <a
                href={article.path}
                tabIndex={-1}
                aria-label={`Article - ${article.title}`}
              >
                <Article key={`${index}-${article.title}`}>
                  {article.thumbnail && (
                    <MediaWrap>
                      <Media
                        {...article.thumbnail}
                        hardcropRatio={md ? 'landscape3' : 'landscape1'}
                        priority={priority && index < 3}
                        sizes="(min-width: 740px) 50vw, 100vw"
                      />
                    </MediaWrap>
                  )}
                  {article.tags && (
                    <ArticleTags>
                      <ArticleTag>
                        {article.tags.map((tag) => (
                          <Chip title={tag.title} />
                        ))}
                      </ArticleTag>
                    </ArticleTags>
                  )}

                  {article.title && (
                    <ArticleTitle>{article.title}</ArticleTitle>
                  )}

                  <ReadArticle>{t('readArticle')}</ReadArticle>
                </Article>
              </a>
            ))}
        </ArticlesGrid>
      </Container>
    </StyledSectionWrapper>
  )
}
