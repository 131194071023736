import { PageBuilder } from '@portfolio/page-builder'
import { PageBuilderSection, PageResponse } from '@portfolio/models'
import { styled } from '@portfolio/styles'

const SidebarPageLayout = styled('div', {
  position: 'relative',
  '@lg': {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr .25fr',
  },
})

const SidebarPageLayoutContainer = styled('div', {
  maxWidth: '$containerMaxWidthForSidebarLayout',
})

const PageWrapper = styled('div', {
  variants: {
    addBottomMargin: {
      true: {
        marginBottom: '$72',
        '@lg': {
          marginBottom: '$96',
        },
      },
    },
  },
})

export const DefaultPage = (args: PageResponse<PageBuilderSection>) => {
  const {
    headerSection,
    pageBuilder,
    sidebarSection,
    addBottomMargin,
    relatedArticles,
  } = args

  const headerElement = headerSection ? (
    <PageBuilder section={{ ...headerSection, priority: true }} />
  ) : null

  const sectionElements = pageBuilder?.map((section, index) => {
    return (
      <PageBuilder
        section={{ ...section, priority: index === 0 || index === 1 }}
        key={`${section._type}-${index}`}
      />
    )
  })

  if (!sidebarSection) {
    return (
      <PageWrapper addBottomMargin={addBottomMargin}>
        {headerElement}
        {sectionElements}
      </PageWrapper>
    )
  } else {
    return (
      <PageWrapper addBottomMargin={addBottomMargin}>
        {headerElement}

        <SidebarPageLayout>
          {sidebarSection && <PageBuilder section={{ ...sidebarSection }} />}
          <SidebarPageLayoutContainer>
            {sectionElements}
          </SidebarPageLayoutContainer>
          <div />
        </SidebarPageLayout>
        {relatedArticles && <PageBuilder section={{ ...relatedArticles }} />}
      </PageWrapper>
    )
  }
}
