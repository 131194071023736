'use client'

import { Chip, Media, Container } from '@portfolio/components'
import { SectionArticleRelatedProps } from '@portfolio/models'
import { styled, useMediaQuery } from '@portfolio/styles'
import { useTranslation } from 'react-i18next'

const ArticlesGrid = styled('div', {
  margin: '0 auto',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gap: '$56',
  paddingTop: '$192',

  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '$64 $24',
    maxWidth: '100%',
  },
})

const Article = styled('div', {
  position: 'relative',
  display: 'grid',
  rowGap: '$32',
  width: '100%',
})

const ArticleTitle = styled('h2', {
  projectFont: 'heading07',
  gridRow: 3,
  gridColumn: 1,
})

const MediaWrap = styled('div', {
  gridColumn: 1,
  gridRow: 1,
  borderRadius: '$r2',
  overflow: 'hidden',
  aspectRatio: '3 / 2',

  '@md': {
    aspectRatio: 'auto',
  },
})

const ReadArticle = styled('span', {
  zIndex: '$content',
  projectFont: 'heading08',
  textDecoration: 'underline',
  gridColumn: 1,
  gridRow: 4,
})

const ArticleTags = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gridColumn: 1,
  gridRow: 2,
  columnGap: '$12',
})

const ArticleTag = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '10px',
})

export const SectionArticleRelated = ({
  relatedArticles,
  priority,
}: SectionArticleRelatedProps) => {
  const { t } = useTranslation('articles')
  const md = useMediaQuery('md')

  return (
    <Container>
      <ArticlesGrid>
        {relatedArticles &&
          relatedArticles.map((article, index: number) => (
            <a
              href={article.path}
              tabIndex={-1}
              aria-label={`Article - ${article.title}`}
            >
              <Article key={`${index}-${article.title}`}>
                {article.thumbnail && (
                  <MediaWrap>
                    <Media
                      {...article.thumbnail}
                      hardcropRatio={md ? 'landscape3' : 'landscape1'}
                      priority={priority && index < 3}
                      sizes="(min-width: 740px) 50vw, 100vw"
                    />
                  </MediaWrap>
                )}
                {article.tags && (
                  <ArticleTags>
                    <ArticleTag>
                      {article.tags.map((tag) => (
                        <Chip title={tag.title} />
                      ))}
                    </ArticleTag>
                  </ArticleTags>
                )}

                {article.title && <ArticleTitle>{article.title}</ArticleTitle>}

                <ReadArticle>{t('readArticle')}</ReadArticle>
              </Article>
            </a>
          ))}
      </ArticlesGrid>
    </Container>
  )
}
